import React from "react";

const DataProtection = () => {
  return (
    <article>
      <section>
        <h2 id="m716">Präambel</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.&nbsp;B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 13. April 2024</p>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img
              src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
              alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
              width={250}
              height={250}
            />
          </a>
        </p>
        <h2>Inhaltsübersicht</h2>
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Präambel
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Übermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              Internationale Datentransfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Aufbewahrung und Löschung von Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Bereitstellung des Onlineangebots und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m134">
              Einsatz von Cookies
            </a>
          </li>
          <li>
            <a className="index-link" href="#m182">
              Kontakt- und Anfrageverwaltung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m263">
              Webanalyse, Monitoring und Optimierung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Präsenzen in sozialen Netzwerken (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m328">
              Plug-ins und eingebettete Funktionen sowie Inhalte
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Änderung und Aktualisierung der Datenschutzerklärung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          xebro GmbH
          <br />
          Am Obstgarten 4<br />
          50170 Kerpen
        </p>
        <p>
          Telefon: 02273-9529707
          <br />
          E-Mail: hello@xebro.de
        </p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
          <li>Geschäfts- und Vertragspartner.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Reichweitenmessung.</li>
          <li>Verwaltung und Beantwortung von Anfragen.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Profile mit nutzerbezogenen Informationen.</li>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>Informationstechnische Infrastruktur.</li>
        </ul>
        <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
        <p>
          <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
          Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
          auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen
          Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b) DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen.
          </li>
          <li>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
            </strong>{" "}
            - die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass
            die Interessen, Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten verlangen, nicht
            überwiegen.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
          Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
          Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
          das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Ferner können
          Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
          gelangen.
        </p>
        <p>
          <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
          Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
          schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als
          auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund
          bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen
          Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet
          werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe
          „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und
          "besonders schützenswerte Personendaten" werden die in der DSGVO
          verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten"
          sowie "berechtigtes Interesse" und "besondere Kategorien von Daten"
          verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im
          Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG
          bestimmt.
        </p>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          Sicherung von Online-Verbindungen durch
          TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der Nutzer,
          die über unsere Online-Dienste übertragen werden, vor unerlaubten
          Zugriffen zu schützen, setzen wir auf die
          TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und
          Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
          Datenübertragung im Internet. Diese Technologien verschlüsseln die
          Informationen, die zwischen der Website oder App und dem Browser des
          Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die
          Daten vor unbefugtem Zugriff geschützt sind. TLS, als die
          weiterentwickelte und sicherere Version von SSL, gewährleistet, dass
          alle Datenübertragungen den höchsten Sicherheitsstandards entsprechen.
          Wenn eine Website durch ein SSL-/TLS-Zertifikat gesichert ist, wird
          dies durch die Anzeige von HTTPS in der URL signalisiert. Dies dient
          als ein Indikator für die Nutzer, dass ihre Daten sicher und
          verschlüsselt übertragen werden.
        </p>
        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass diese an andere Stellen, Unternehmen, rechtlich
          selbstständige Organisationseinheiten oder Personen übermittelt
          beziehungsweise ihnen gegenüber offengelegt werden. Zu den Empfängern
          dieser Daten können z.&nbsp;B. mit IT-Aufgaben beauftragte
          Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in
          eine Website eingebunden sind. In solchen Fall beachten wir die
          gesetzlichen Vorgaben und schließen insbesondere entsprechende
          Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit
          den Empfängern Ihrer Daten ab.
        </p>
        <h2 id="m24">Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
          (d.&nbsp;h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen
          der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
          Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
          stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
          Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines
          Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient
          dieser als Grundlage des Datentransfers. Im Übrigen erfolgen
          Datentransfers nur dann, wenn das Datenschutzniveau anderweitig
          gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46
          Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall
          vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49
          Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der
          Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland
          mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig
          gelten. Informationen zu Drittlandtransfers und vorliegenden
          Angemessenheitsbeschlüssen können dem Informationsangebot der
          EU-Kommission entnommen werden:{" "}
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
            target="_blank"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
          </a>
        </p>
        <p>
          EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
          „Data Privacy Framework" (DPF) hat die EU-Kommission das
          Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
          Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
          anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
          Informationen zu dem DPF können Sie der Website des
          Handelsministeriums der USA unter{" "}
          <a href="https://www.dataprivacyframework.gov/" target="_blank">
            https://www.dataprivacyframework.gov/
          </a>
          (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
          Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter
          dem Data Privacy Framework zertifiziert sind.
        </p>
        <h2 id="m12">Aufbewahrung und Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z.&nbsp;B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist
          oder sie für den Zweck nicht erforderlich sind). Sofern die Daten
          nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
          Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke
          beschränkt. D.&nbsp;h., die Daten werden gesperrt und nicht für andere
          Zwecke verarbeitet. Das gilt z.&nbsp;B. für Daten, die aus handels-
          oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
          Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist. Im Rahmen unserer
          Datenschutzhinweise können wir den Nutzern weitere Informationen zu
          der Löschung sowie zu der Aufbewahrung von Daten mitteilen, die
          speziell für die jeweiligen Verarbeitungsprozesses gelten. Sofern im
          Hinblick auf ein Datum mehrere Angaben zur Aufbewahrungsdauer oder
          Löschungsfristen gemacht werden, gelten jeweils die längeren Angaben.
          Sofern bei Fristen kein Beginn der Fristen genannt wird, beginnen sie
          bei einer Laufzeit von mindestens einem Jahr grundsätzlich mit dem
          Ende des Kalenderjahres, in dem das fristauslösende Ereignis
          stattgefunden hat. Sofern Daten nicht für den Zweck für den sie
          erhoben wurden, sondern aus gesetzlichen oder anderen Gründen
          aufbewahrt oder archiviert werden, erfolgt die Verarbeitung dieser
          Daten ausschließlich zu Zwecken, aufgrund welcher sie aufbewahrt oder
          archiviert werden.
        </p>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
          Betroffene nach der DSGVO verschiedene Rechte zu, die sich
          insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
            werden und auf Auskunft über diese Daten sowie auf weitere
            Informationen und Kopie der Daten entsprechend den gesetzlichen
            Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong>
              Recht auf Löschung und Einschränkung der Verarbeitung:
            </strong>{" "}
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
            bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
            Einschränkung der Verarbeitung der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
            einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Entsprechend den
            gesetzlichen Vorgaben und unbeschadet eines anderweitigen
            verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie
            ferner das Recht, bei einer Datenschutzaufsichtsbehörde,
            insbesondere einer Aufsichtsbehörde im Mitgliedstaat, in dem Sie
            sich gewöhnlich aufhalten, der Aufsichtsbehörde Ihres Arbeitsplatzes
            oder des Ortes des mutmaßlichen Verstoßes, eine Beschwerde
            einzulegen, wenn Sie der Ansicht sei sollten, dass die Verarbeitung
            der Ihre Person betreffenden personenbezogenen Daten gegen die DSGVO
            verstößt.
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
          zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
          IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
          Funktionen unserer Online-Dienste an den Browser oder das Endgerät der
          Nutzer zu übermitteln.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen); Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen). Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beiträge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten). Geschäfts- und
            Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
            und technischen Geräten (Computer, Server etc.).).
            Sicherheitsmaßnahmen.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
            </strong>
            Für die Bereitstellung unseres Onlineangebotes nutzen wir
            Speicherplatz, Rechenkapazität und Software, die wir von einem
            entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder
            anderweitig beziehen;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
            Zugriff auf unser Onlineangebot wird in Form von so genannten
            "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
            Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
            Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
            erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
            des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
            Regelfall IP-Adressen und der anfragende Provider gehören. Die
            Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
            werden, z.&nbsp;B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken) und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).{" "}
            </span>
            <strong>Löschung von Daten:</strong> Logfile-Informationen werden
            für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht
            oder anonymisiert. Daten, deren weitere Aufbewahrung zu
            Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
            jeweiligen Vorfalls von der Löschung ausgenommen.
          </li>
          <li>
            <strong>Amazon Web Services (AWS): </strong>Leistungen auf dem
            Gebiet der Bereitstellung von informationstechnischer Infrastruktur
            und verbundenen Dienstleistungen (z.&nbsp;B. Speicherplatz und/oder
            Rechenkapazitäten); <strong>Dienstanbieter:</strong> Amazon Web
            Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://aws.amazon.com/de/" target="_blank">
              https://aws.amazon.com/de/
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a href="https://aws.amazon.com/de/privacy/" target="_blank">
              https://aws.amazon.com/de/privacy/
            </a>
            ;<strong>Auftragsverarbeitungsvertrag:</strong>{" "}
            <a
              href="https://aws.amazon.com/de/compliance/gdpr-center/"
              target="_blank"
            >
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            .<strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF).
          </li>
        </ul>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>
          Cookies sind kleine Textdateien bzw. sonstige Speichervermerke, die
          Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum
          Beispiel, um den Log-in-Status in einem Nutzerkonto, einen
          Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
          verwendete Funktionen eines Onlineangebots zu speichern. Cookies
          können ferner in Bezug auf unterschiedliche Anliegen Einsatz finden,
          etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und des
          Komforts von Onlineangeboten sowie der Erstellung von Analysen der
          Besucherströme.
        </p>
        <p>
          <strong>Hinweise zur Einwilligung:&nbsp;</strong>Wir setzen Cookies im
          Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von
          den Nutzern eine vorhergehende Einwilligung ein, es sei denn, sie ist
          laut Gesetzeslage nicht gefordert. Eine Erlaubnis ist insbesondere
          nicht notwendig, wenn das Speichern und das Auslesen der
          Informationen, also auch von Cookies, unbedingt erforderlich sind, um
          den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst
          (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
          Einwilligung wird ihnen gegenüber deutlich kommuniziert und enthält
          die Informationen zur jeweiligen Cookie-Nutzung.
        </p>
        <p>
          <strong>
            Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:&nbsp;
          </strong>
          Auf welcher datenschutzrechtlichen Grundlage wir die personenbezogenen
          Daten der Nutzer mithilfe von Cookies verarbeiten, hängt davon ab, ob
          wir sie um eine Einwilligung bitten. Falls die Nutzer akzeptieren, ist
          die Rechtsgrundlage der Verwertung ihrer Daten die erklärte
          Einwilligung. Andernfalls werden die mithilfe von Cookies verwerteten
          Daten auf Grundlage unserer berechtigten Interessen (z.&nbsp;B. an
          einem betriebswirtschaftlichen Betrieb unseres Onlineangebots und der
          Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im
          Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der
          Einsatz von Cookies erforderlich ist, um unseren vertraglichen
          Verpflichtungen nachzukommen. Zu welchen Zwecken die Cookies von uns
          verwertet werden, darüber klären wir im Laufe dieser
          Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und
          Verarbeitungsprozessen auf.
        </p>
        <p>
          <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
          werden die folgenden Arten von Cookies unterschieden:
        </p>
        <ul>
          <li>
            <strong>
              Temporäre Cookies (auch: Session- oder Sitzungscookies):
            </strong>{" "}
            Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
            Onlineangebot verlassen und sein Endgerät (z.&nbsp;B. Browser oder
            mobile Applikation) geschlossen hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
            nach dem Schließen des Endgeräts gespeichert. So können
            beispielsweise der Log-in-Status gespeichert und bevorzugte Inhalte
            direkt angezeigt werden, wenn der Nutzer eine Website erneut
            besucht. Ebenso können die mithilfe von Cookies erhobenen
            Nutzerdaten zur Reichweitenmessung Verwendung finden. Sofern wir
            Nutzern keine expliziten Angaben zur Art und Speicherdauer von
            Cookies mitteilen (z.&nbsp;B. im Rahmen der Einholung der
            Einwilligung), sollten sie davon ausgehen, dass diese permanent sind
            und die Speicherdauer bis zu zwei Jahre betragen kann.
          </li>
        </ul>
        <p>
          <strong>
            Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out):&nbsp;
          </strong>
          Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
          widerrufen und zudem einen Widerspruch gegen die Verarbeitung
          entsprechend den gesetzlichen Vorgaben, auch mittels der
          Privatsphäre-Einstellungen ihres Browsers, erklären.
        </p>
        <ul className="m-elements">
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
            DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
            </strong>
            Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die
            Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
            Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
            Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
            Protokollierung, Verwaltung und dem Widerruf von Einwilligungen,
            insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren
            Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung
            von Informationen auf den Endgeräten der Nutzer eingesetzt werden.
            Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für
            die Nutzung von Cookies und die damit verbundenen Verarbeitungen von
            Informationen, einschließlich der im
            Einwilligungs-Management-Verfahren genannten spezifischen
            Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die
            Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die
            Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage
            zu vermeiden und den Nachweis der Einwilligung gemäß der
            gesetzlichen Anforderungen führen zu können. Die Speicherung erfolgt
            serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie)
            oder mittels vergleichbarer Technologien, um die Einwilligung einem
            spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern
            keine spezifischen Angaben zu den Anbietern von
            Einwilligungs-Management-Diensten vorliegen, gelten folgende
            allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung
            beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer
            Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der
            Einwilligung, den Angaben zum Umfang der Einwilligung (z.&nbsp;B.
            betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie
            Informationen über den Browser, das System und das verwendete
            Endgerät gespeichert wird;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m182">Kontakt- und Anfrageverwaltung</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.&nbsp;B. per Post, Kontaktformular,
          E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
          Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
          Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen
          und etwaiger angefragter Maßnahmen erforderlich ist.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.&nbsp;B.
            Post- und E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beiträge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz,
            verwendete Gerätetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback
            (z.&nbsp;B. Sammeln von Feedback via Online-Formular).
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Kontaktformular: </strong>Wenn Nutzer über unser
            Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in
            Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
            mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
        <p>
          Die Webanalyse (auch als „Reichweitenmessung" bezeichnet) dient der
          Auswertung der Besucherströme unseres Onlineangebots und kann
          Verhalten, Interessen oder demografische Informationen zu den
          Besuchern, wie beispielsweise Alter oder Geschlecht, als pseudonyme
          Werte umfassen. Mithilfe der Reichweitenanalyse können wir zum
          Beispiel erkennen, zu welcher Zeit unser Onlineangebot oder dessen
          Funktionen beziehungsweise Inhalte am häufigsten genutzt werden, oder
          zur Wiederverwendung einladen. Ebenso ist es uns möglich,
          nachzuvollziehen, welche Bereiche der Optimierung bedürfen.{" "}
        </p>
        <p>
          Neben der Webanalyse können wir auch Testverfahren einsetzen, um etwa
          unterschiedliche Versionen unseres Onlineangebots oder seiner
          Bestandteile zu testen und zu optimieren.
        </p>
        <p>
          Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
          Profile, also zu einem Nutzungsvorgang zusammengefasste Daten,
          angelegt und Informationen in einem Browser bzw. in einem Endgerät
          gespeichert und dann ausgelesen werden. Zu den erhobenen Angaben
          gehören insbesondere besuchte Websites und dort genutzte Elemente
          sowie technische Auskünfte, wie etwa der verwendete Browser, das
          benutzte Computersystem sowie Angaben zu Nutzungszeiten. Sofern sich
          Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder
          gegenüber den Anbietern der von uns eingesetzten Dienste einverstanden
          erklärt haben, ist auch die Verarbeitung von Standortdaten möglich.
        </p>
        <p>
          Darüber hinaus werden die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir ein IP-Masking-Verfahren (d. h. Pseudonymisierung durch
          Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im
          Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten
          der Nutzer (wie z.&nbsp;B. E-Mail-Adressen oder Namen) gespeichert,
          sondern Pseudonyme. Das heißt, wir als auch die Anbieter der
          eingesetzten Software kennen nicht die tatsächliche Identität der
          Nutzer, sondern nur die zum Zweck der jeweiligen Verfahren in deren
          Profilen gespeicherten Angaben.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um deren Einwilligung in den Einsatz der Drittanbieter bitten, stellt
          die Rechtsgrundlage der Datenverarbeitung die Einwilligung dar.
          Ansonsten werden die Nutzerdaten auf Grundlage unserer berechtigten
          Interessen (d. h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen). Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
            (z.&nbsp;B. Zugriffsstatistiken, Erkennung wiederkehrender
            Besucher). Profile mit nutzerbezogenen Informationen (Erstellen von
            Nutzerprofilen).
          </li>
          <li>
            <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung
            der IP-Adresse).
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Matomo: </strong>Bei Matomo handelt es sich um eine
            Software, die zu Zwecken der Webanalyse und Reichweitenmessung
            eingesetzt wird. Im Rahmen der Nutzung von Matomo werden Cookies
            erzeugt und auf dem Endgerät der Nutzer gespeichert. Die im Rahmen
            der Nutzung von Matomo erhobenen Daten der Nutzer werden nur von uns
            verarbeitet und nicht mit Dritten geteilt. Die Cookies werden für
            einen maximalen Zeitraum von 13 Monaten gespeichert:{" "}
            <a href="https://matomo.org/faq/general/faq_146/" target="_blank">
              https://matomo.org/faq/general/faq_146/
            </a>
            ;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO).{" "}
            </span>
            <strong>Löschung von Daten:</strong> Die Cookies haben eine
            Speicherdauer von maximal 13 Monaten.
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Nutzerdaten, um mit den dort aktiven
          Nutzern zu kommunizieren oder Informationen über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Nutzerdaten außerhalb des Raumes der
          Europäischen Union verarbeitet werden können. Hierdurch können sich
          für die Nutzer Risiken ergeben, weil so zum Beispiel die Durchsetzung
          der Nutzerrechte erschwert werden könnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          beispielsweise anhand des Nutzungsverhaltens und sich daraus
          ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
          Letztere finden möglicherweise wiederum Verwendung, um etwa
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Daher werden im
          Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen
          das Nutzungsverhalten und die Interessen der Nutzer gespeichert
          werden. Zudem können in den Nutzungsprofilen auch Daten unabhängig der
          von den Nutzern verwendeten Geräten gespeichert werden (insbesondere,
          wenn sie Mitglieder der jeweiligen Plattformen und dort eingeloggt
          sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur Letztere haben
          jeweils Zugriff auf die Nutzerdaten und können direkt entsprechende
          Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
          benötigen, dann können Sie sich an uns wenden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.&nbsp;B.
            Post- und E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beiträge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz,
            verwendete Gerätetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation; Feedback (z.&nbsp;B. Sammeln von Feedback via
            Online-Formular). Marketing.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen
            Netzwerks Facebook;
            <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited,
            Merrion Road, Dublin 4, D04 X2K5, Irland;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://www.facebook.com" target="_blank">
              https://www.facebook.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a href="https://www.facebook.com/about/privacy" target="_blank">
              https://www.facebook.com/about/privacy
            </a>
            ;<strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF); <strong>Weitere Informationen:</strong> Wir sind
            gemeinsam mit Meta Platforms Ireland Limited für die Erhebung
            (jedoch nicht die weitere Verarbeitung) von Daten der Besucher
            unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu diesen
            Daten gehören Informationen zu den Arten von Inhalten, die Nutzer
            sich ansehen oder mit denen sie interagieren, oder die von ihnen
            vorgenommenen Handlungen (siehe unter „Von dir und anderen getätigte
            und bereitgestellte Dinge" in der Facebook-Datenrichtlinie:{" "}
            <a href="https://www.facebook.com/policy" target="_blank">
              https://www.facebook.com/policy
            </a>
            ), sowie Informationen über die von den Nutzern genutzten Geräte
            (z.&nbsp;B. IP-Adressen, Betriebssystem, Browsertyp,
            Spracheinstellungen, Cookie-Daten; siehe unter „Geräteinformationen"
            in der Facebook-Datenrichtlinie:{" "}
            <a href="https://www.facebook.com/policy" target="_blank">
              https://www.facebook.com/policy
            </a>
            ). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir
            diese Informationen?" erläutert, erhebt und verwendet Facebook
            Informationen auch, um Analysedienste, so genannte
            "Seiten-Insights", für Seitenbetreiber bereitzustellen, damit diese
            Erkenntnisse darüber erhalten, wie Personen mit ihren Seiten und mit
            den mit ihnen verbundenen Inhalten interagieren. Wir haben mit
            Facebook eine spezielle Vereinbarung abgeschlossen ("Informationen
            zu Seiten-Insights",{" "}
            <a
              href="https://www.facebook.com/legal/terms/page_controller_addendum"
              target="_blank"
            >
              https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
            ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
            Facebook beachten muss und in der Facebook sich bereit erklärt hat
            die Betroffenenrechte zu erfüllen (d.&nbsp;.h. Nutzer können
            z.&nbsp;B. Auskünfte oder Löschungsanfragen direkt an Facebook
            richten). Die Rechte der Nutzer (insbesondere auf Auskunft,
            Löschung, Widerspruch und Beschwerde bei zuständiger
            Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook
            nicht eingeschränkt. Weitere Hinweise finden sich in den
            "Informationen zu Seiten-Insights" (
            <a
              href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
              target="_blank"
            >
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            ). Die gemeinsame Verantwortlichkeit beschränkt sich auf die
            Erhebung durch und Übermittlung von Daten an Meta Platforms Ireland
            Limited, ein Unternehmen mit Sitz in der EU. Die weitere
            Verarbeitung der Daten liegt in der alleinigen Verantwortung von
            Meta Platforms Ireland Limited, was insbesondere die Übermittlung
            der Daten an die Muttergesellschaft Meta Platforms, Inc. in den USA
            betrifft.
          </li>
          <li>
            <strong>LinkedIn: </strong>Soziales Netzwerk;{" "}
            <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company,
            Wilton Place, Dublin 2, Irland;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://www.linkedin.com" target="_blank">
              https://www.linkedin.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ;<strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF); <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .<strong>Weitere Informationen:</strong> Wir sind gemeinsam mit
            LinkedIn Irland Unlimited Company für die Erhebung (jedoch nicht die
            weitere Verarbeitung) von Daten der Besucher, die zu Zwecken der
            Erstellung der „Page-Insights" (Statistiken) unserer
            LinkedIn-Profile verantwortlich. <br />
            Zu diesen Daten gehören Informationen zu den Arten von Inhalten, die
            Nutzer sich ansehen oder mit denen sie interagieren, oder die von
            ihnen vorgenommenen Handlungen sowie Informationen über die von den
            Nutzern genutzten Geräte (z.&nbsp;B. IP-Adressen, Betriebssystem,
            Browsertyp, Spracheinstellungen, Cookie-Daten) und Angaben aus dem
            Profil der Nutzer, wie Berufsfunktion, Land, Branche,
            Hierarchieebene, Unternehmensgröße und Beschäftigungsstatus.
            Datenschutzinformationen zur Verarbeitung von Daten der Nutzer durch
            LinkedIn können den Datenschutzhinweisen von LinkedIn entnommen
            werden:{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            <br />
            Wir haben mit LinkedIn Irland eine spezielle Vereinbarung
            abgeschlossen ("Page Insights Joint Controller Addendum (the
            ‚Addendum‘)",{" "}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
            LinkedIn beachten muss und in der LinkedIn sich bereit erklärt hat
            die Betroffenenrechte zu erfüllen (d. h. Nutzer können z.&nbsp;B.
            Auskünfte oder Löschungsanfragen direkt an LinkedIn richten). Die
            Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch
            und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
            Vereinbarungen mit LinkedIn nicht eingeschränkt. Die gemeinsame
            Verantwortlichkeit beschränkt sich auf die Erhebung der Daten durch
            und die Übermittlung an die Ireland Unlimited Company, ein
            Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten
            obliegt ausschließlich der Ireland Unlimited Company, was
            insbesondere die Übermittlung der Daten an die Muttergesellschaft
            LinkedIn Corporation in den USA betrifft.
          </li>
          <li>
            <strong>X: </strong>Soziales Netzwerk;{" "}
            <strong>Dienstanbieter:</strong> Twitter International Company, One
            Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).{" "}
            </span>
            <strong>Datenschutzerklärung:</strong>
            <a href="https://twitter.com/privacy" target="_blank">
              https://twitter.com/privacy
            </a>
            , (Settings:{" "}
            <a href="https://twitter.com/personalization" target="_blank">
              https://twitter.com/personalization
            </a>
            ).
          </li>
          <li>
            <strong>Xing: </strong>Soziales Netzwerk;{" "}
            <strong>Dienstanbieter:</strong> New Work SE, Am Strandkai 1, 20457
            Hamburg, Deutschland;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://www.xing.com/" target="_blank">
              https://www.xing.com/
            </a>
            .<strong>Datenschutzerklärung:</strong>{" "}
            <a
              href="https://privacy.xing.com/de/datenschutzerklaerung"
              target="_blank"
            >
              https://privacy.xing.com/de/datenschutzerklaerung
            </a>
            .
          </li>
        </ul>
        <h2 id="m328">Plug-ins und eingebettete Funktionen sowie Inhalte</h2>
        <p>
          Wir binden Funktions- und Inhaltselemente in unser Onlineangebot ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend als
          „Drittanbieter" bezeichnet) bezogen werden. Dabei kann es sich zum
          Beispiel um Grafiken, Videos oder Stadtpläne handeln (nachfolgend
          einheitlich als „Inhalte" bezeichnet).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne IP-Adresse
          die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
          damit für die Darstellung dieser Inhalte oder Funktionen erforderlich.
          Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
          anzuwenden. Drittanbieter können ferner sogenannte Pixel-Tags
          (unsichtbare Grafiken, auch als „Web Beacons" bezeichnet) für
          statistische oder Marketingzwecke einsetzen. Durch die „Pixel-Tags"
          können Informationen, wie etwa der Besucherverkehr auf den Seiten
          dieser Website, ausgewertet werden. Die pseudonymen Informationen
          können darüber hinaus in Cookies auf dem Gerät der Nutzer gespeichert
          werden und unter anderem technische Auskünfte zum Browser und zum
          Betriebssystem, zu verweisenden Websites, zur Besuchszeit sowie
          weitere Angaben zur Nutzung unseres Onlineangebots enthalten, aber
          auch mit solchen Informationen aus anderen Quellen verbunden werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um ihre Einwilligung in den Einsatz der Drittanbieter bitten, stellt
          die Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar. Ansonsten
          werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen
          (d. h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen). Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Font Awesome (Bezug vom Server des Anbieters): </strong>
            Bezug von Schriften (sowie Symbolen) zum Zwecke einer technisch
            sicheren, wartungsfreien und effizienten Nutzung von Schriften und
            Symbolen im Hinblick auf Aktualität und Ladezeiten, deren
            einheitliche Darstellung und Berücksichtigung möglicher
            lizenzrechtlicher Beschränkungen. Dem Anbieter der Schriftarten wird
            die IP-Adresse des Nutzers mitgeteilt, damit die Schriftarten im
            Browser des Nutzers zur Verfügung gestellt werden können. Darüber
            hinaus werden technische Daten (Spracheinstellungen,
            Bildschirmauflösung, Betriebssystem, verwendete Hardware)
            übermittelt, die für die Bereitstellung der Schriften in
            Abhängigkeit von den verwendeten Geräten und der technischen
            Umgebung notwendig sind; <strong>Dienstanbieter:</strong> Fonticons,
            Inc. ,6 Porter Road Apartment 3R, Cambridge, MA 02140, USA;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://fontawesome.com/" target="_blank">
              https://fontawesome.com/
            </a>
            . <strong>Datenschutzerklärung:</strong>
            <a href="https://fontawesome.com/privacy" target="_blank">
              https://fontawesome.com/privacy
            </a>
            .
          </li>
        </ul>
        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.&nbsp;B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
          Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
          Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem
          dem Verständnis dienen.
        </p>
        <ul className="glossary">
          <li>
            <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen Informationen,
            die im Zuge der Erstellung, Bearbeitung und Veröffentlichung von
            Inhalten aller Art generiert werden. Diese Kategorie von Daten kann
            Texte, Bilder, Videos, Audiodateien und andere multimediale Inhalte
            einschließen, die auf verschiedenen Plattformen und Medien
            veröffentlicht werden. Inhaltsdaten sind nicht nur auf den
            eigentlichen Inhalt beschränkt, sondern beinhalten auch Metadaten,
            die Informationen über den Inhalt selbst liefern, wie Tags,
            Beschreibungen, Autoreninformationen und Veröffentlichungsdaten
          </li>
          <li>
            <strong>Kontaktdaten:</strong> Kontaktdaten sind essentielle
            Informationen, die die Kommunikation mit Personen oder
            Organisationen ermöglichen. Sie umfassen u.a. Telefonnummern,
            postalische Adressen und E-Mail-Adressen, sowie Kommunikationsmittel
            wie soziale Medien-Handles und Instant-Messaging-Identifikatoren.
          </li>
          <li>
            <strong>Meta-, Kommunikations- und Verfahrensdaten:</strong> Meta-,
            Kommunikations- und Verfahrensdaten sind Kategorien, die
            Informationen über die Art und Weise enthalten, wie Daten
            verarbeitet, übermittelt und verwaltet werden. Meta-Daten, auch
            bekannt als Daten über Daten, umfassen Informationen, die den
            Kontext, die Herkunft und die Struktur anderer Daten beschreiben.
            Sie können Angaben zur Dateigröße, dem Erstellungsdatum, dem Autor
            eines Dokuments und den Änderungshistorien beinhalten.
            Kommunikationsdaten erfassen den Austausch von Informationen
            zwischen Nutzern über verschiedene Kanäle, wie E-Mail-Verkehr,
            Anrufprotokolle, Nachrichten in sozialen Netzwerken und
            Chat-Verläufe, inklusive der beteiligten Personen, Zeitstempel und
            Übertragungswege. Verfahrensdaten beschreiben die Prozesse und
            Abläufe innerhalb von Systemen oder Organisationen, einschließlich
            Workflow-Dokumentationen, Protokolle von Transaktionen und
            Aktivitäten, sowie Audit-Logs, die zur Nachverfolgung und
            Überprüfung von Vorgängen verwendet werden.
          </li>
          <li>
            <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen sich auf
            Informationen, die erfassen, wie Nutzer mit digitalen Produkten,
            Dienstleistungen oder Plattformen interagieren. Diese Daten umfassen
            eine breite Palette von Informationen, die aufzeigen, wie Nutzer
            Anwendungen nutzen, welche Funktionen sie bevorzugen, wie lange sie
            auf bestimmten Seiten verweilen und über welche Pfade sie durch eine
            Anwendung navigieren. Nutzungsdaten können auch die Häufigkeit der
            Nutzung, Zeitstempel von Aktivitäten, IP-Adressen,
            Geräteinformationen und Standortdaten einschließen. Sie sind
            besonders wertvoll für die Analyse des Nutzerverhaltens, die
            Optimierung von Benutzererfahrungen, das Personalisieren von
            Inhalten und das Verbessern von Produkten oder Dienstleistungen.
            Darüber hinaus spielen Nutzungsdaten eine entscheidende Rolle beim
            Erkennen von Trends, Vorlieben und möglichen Problembereichen
            innerhalb digitaler Angebote
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten"
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden "betroffene
            Person") beziehen; als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z.&nbsp;B. Cookie) oder zu
            einem oder mehreren besonderen Merkmalen identifiziert werden kann,
            die Ausdruck der physischen, physiologischen, genetischen,
            psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
            dieser natürlichen Person sind.
          </li>
          <li>
            <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
            Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw.
            kurz "Profilen" umfasst jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen (je nach Art
            der Profilbildung können dazu unterschiedliche Informationen
            betreffend die Demographie, Verhalten und Interessen, wie z.&nbsp;B.
            die Interaktion mit Webseiten und deren Inhalten, etc.) zu
            analysieren, zu bewerten oder, um sie vorherzusagen (z.&nbsp;B. die
            Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten
            auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des
            Profilings werden häufig Cookies und Web-Beacons eingesetzt.
          </li>
          <li>
            <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch
            als Web Analytics bezeichnet) dient der Auswertung der
            Besucherströme eines Onlineangebotes und kann das Verhalten oder
            Interessen der Besucher an bestimmten Informationen, wie z.&nbsp;B.
            Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse
            können Betreiber von Onlineangeboten z.&nbsp;B. erkennen, zu welcher
            Zeit Nutzer ihre Webseiten besuchen und für welche Inhalte sie sich
            interessieren. Dadurch können sie z.&nbsp;B. die Inhalte der
            Webseiten besser an die Bedürfnisse ihrer Besucher anpassen. Zu
            Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und
            Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
            so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
            bezeichnet.
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
            Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.
          </li>
        </ul>
      </section>
    </article>
  );
};

export default DataProtection;
