import Stage from "../Stage/Stage";
import React from "react";

const NoPage = () => {
  return (
    <>
      <Stage headline="Not Found">
        <p>There is no content located under this path.</p>
      </Stage>
    </>
  );
};

export default NoPage;
