import css from "./Stage.module.scss";

const Stage = ({ headline = "headline", children }) => {
  return (
    <div className={css.stage}>
      <div>
        <h1>{headline}</h1>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Stage;
