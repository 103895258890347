import css from "./Logo.module.scss";
import {useEffect, useState} from "react";

const Logo = () => {

    return <div className={css.logo}>
        <span></span>
        <div>X</div>
    </div>

}

export default Logo;
