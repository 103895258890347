import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faXing,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import css from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={css.footer}>
      <div className={css.wrapper}>
        <div>©{new Date().getFullYear()} xebro GmbH</div>
        <div>
          <div>
            <Link to="/impressum" className={css.link}>
              Impressum
            </Link>
            <Link to="/datenschutz" className={css.link}>
              Datenschutz
            </Link>
          </div>
          <div>
            <Link
              to="https://www.xing.com/pages/xebro-gmbh"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faXing} className={css.icon} />
            </Link>
            <Link
              to="https://www.linkedin.com/company/xebro-gmbh"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} className={css.icon} />
            </Link>
            <Link
              to="https://github.com/xebro-gmbh/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} className={css.icon} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
