import css from "../Layout/Layout.module.scss";

const Section = ({ title, children }) => {
  return (
    <section className={css.type_block}>
      <div>
        <h3>{title}</h3>
      </div>
      <div>{children}</div>
    </section>
  );
};

export default Section;
