import Stage from "../Stage/Stage";
import React from "react";
import Section from "../Section/Section";
import CallToAction from "../CallToAction/CallToAction";

const Home = () => {
  return (
    <>
      <Stage headline="Softwareentwicklung und Beratung">
        <p>
          Jedes Projekt ist einzigartig von seinen Aufgaben und mit jeder
          Aufgabe lernen wir etwas Neues und wachsen daran, auch zusammen.
        </p>
        <p>Lassen Sie uns gemeinsam etwas verändern.</p>
      </Stage>
      <article>
        <Section title="Was machen wir">
          <p>
            Wir entwickeln Lösungen für Probleme unserer Kunden aus dem
            E-Commerce Bereich.
          </p>
          <p>
            Dabei sind wir Experte auf dem Gebiet Backend-Entwicklung mit PHP
            und die damit verbundenen speziellen Anforderungen an Architektur,
            Datenverarbeitung und Betrieb.
          </p>
        </Section>
        <Section title="Wie machen wir es">
          <p>
            Am besten gelingt das mit messbaren Zielen und einem klaren
            Verständnis der Problemstellung, erst dann wählt man das passende
            Werkzeug und definiert dadurch die Lösung.
          </p>
          <p>
            Aber der wichtigste Faktor für den Erfolg eines jeden Projektes ist
            die Kommunikation zwischen allen Beteiligten, denn
            Softwareentwicklung ist Teamarbeit.
          </p>
        </Section>
        <Section title="Wer sind wir">
          <p>
            Wir leben Softwareentwicklung, denn aus der Begeisterung für das
            Programmieren im Privaten, wurde vor über 11 Jahren eine berufliche
            Passion, welche sich über die Jahre zu der xebro GmbH von heute
            entwickelt hat.
          </p>
          <p>
            Was sich bis heute nicht geändert hat, ist die Begeisterung, Neues
            zu lernen und durch immer neue Herausforderungen zu wachsen.
          </p>
        </Section>
        <CallToAction
          headline="Lassen Sie uns etwas verändern."
          calendly="https://calendly.com/dan-vhsh/30min"
          email="hello@xebro.de"
        >
          <p>
            Wir können unverbindlich sprechen und zusammen herausfinden, wie wir
            zusammen etwas verbessern können.
          </p>
        </CallToAction>
      </article>
    </>
  );
};

export default Home;
