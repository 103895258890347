import css from "./Layout.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faLock, faSection } from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Logo from "./Logo";

const Layout = () => {
  return (
    <>
      <header className={css.header}>
        <div className={css.banner}>
          <Logo />
          <div className={css.company}>
            <h1>
              xebro <span>GmbH</span>
            </h1>
          </div>
        </div>
        <nav className={css.nav}>
          <Link to="/">
            <FontAwesomeIcon icon={faHouse} className={css.icon} />
            Home
          </Link>
          <a href="/impressum">
            <FontAwesomeIcon icon={faSection} className={css.icon} />
            Impressum
          </a>
          <a href="/datenschutz">
            <FontAwesomeIcon icon={faLock} className={css.icon} />
            Datenschutz
          </a>
        </nav>
      </header>

      <main className={css.main}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
