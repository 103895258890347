import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "./Layout/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import ReactDOM from "react-dom/client";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BaseLayout />}>
            <Route index element={<Home />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/datenschutz" element={<DataProtection />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
