import css from "./CallToAction.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const CallToAction = ({ headline, email, calendly, children }) => {
  return (
    <section className={css.type_contact}>
      <div className={css.small}>
        <h3 className={css.headline}>{headline}</h3>
        {children}
      </div>
      <div className={css.buttons}>
        <Link to={calendly} className={css.btn}>
          <FontAwesomeIcon icon={faCalendar} className={css.btn_icon} />
          Termin
        </Link>
        <Link to={"mailto:" + email} className={css.btn + " " + css.accent}>
          <FontAwesomeIcon icon={faEnvelope} className={css.btn_icon} />
          E-Mail
        </Link>
      </div>
    </section>
  );
};

export default CallToAction;
